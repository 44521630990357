import React, { createContext, useState } from 'react';

// Create a context with a default language of German ('de')
export const LanguageContext = createContext({
  language: 'de',
  setLanguage: () => {}
});

// Provider component to wrap your app and manage language state
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('de'); // default is German

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

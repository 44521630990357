// testimonials.tsx

export const Testimonials = () => {
  return (
    <div id="testimonials" style={{ textAlign: 'center' }}>
      <div className="container">
        <div className="section-title">
          <h2 style={{ textAlign: 'center' }}>RENESALIS PACKAGING</h2>
        </div>
        <div className="contact-info">
          <p style={{ textAlign: 'center', margin: '0 auto' }}>
            <strong>Renesalis d.o.o.</strong><br />
            Igmanska bb<br />
            71320 Vogosca - Kanton Sarajevo<br />
            Bosnia and Herzegovina (BiH)<br />
            office@renesalis-packaging.com<br />
          </p>
        </div>
        {/* Instagram Link */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <a
            href="https://www.instagram.com/RenesalisPackaging/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: '#000', // Change color as needed
            }}
          >
            {' '}
            <span
              style={{
                marginLeft: '5px',
                fontSize: '18px',
              }}
              className="fa fa-instagram"
            ></span>
          </a>
        </div>
      </div>
    </div>
  );
};